<template>
  <div class="search-left">
    <h4>筛选条件</h4>
    <div class="search-left-box">
      <el-form :model="queryForm" label-position="top">
        <el-form-item label="用户">
          <el-input
            v-model="queryForm.author"
            placeholder="用户ID..."
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="群组">
          <el-input
            v-model="queryForm.group"
            placeholder="群组ID..."
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="多媒体消息">
          <el-select
            v-model="queryForm.is_media"
            placeholder="请选择"
            clearable
            style="width: 100%;"
          >
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开始">
          <el-date-picker
            style="width: 100%;"
            v-model="queryForm.send_time__gte"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择消息发送日期时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束">
          <el-date-picker
            style="width: 100%;"
            v-model="queryForm.send_time__lte"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择消息发送日期时间"
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryForm: {}
    }
  }
}
</script>
<style scoped lang="scss">
.search-left {
  width: 100%;
  height: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  h4 {
    padding: 0 10px;
  }
  .search-left-box {
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    ::v-deep .el-form-item__label {
      margin-bottom: 0 !important;
      padding: 0;
    }
  }
}
</style>
