<template>
  <div class="msg-container">
    <div class="msg-left">
      <SearchLeft ref="searchLeftRef" />
    </div>
    <div class="bottom_box" v-loading="listLoading">
      <div class="content_t" id="search-msg-list">
        <SingleMessage
          :detailType="true"
          v-for="item in list"
          :key="item.id"
          :singleInfo="item"
          @updateFun="updateFun"
          tagType="msg"
        />
      </div>
      <div v-if="list.length == 0" class="no-data">
        暂无数据
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page_index"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
    <div class="msg-right">
      <searchRight ref="searchRightRef" @submitFun="submitFun" />
    </div>
  </div>
</template>

<script>
import { messagesApi } from '@/api/whatsApp'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import { getManagerStatus } from '@/utils/manager'
import SingleMessage from './componentens/SingleMessage.vue'
import SearchLeft from './componentens/SearchLeft.vue'
import { scrollTopFun } from '@/plugins/fun'
import { cacheMixin } from '@/mixins/mixin'
import { requestMixin } from '@/mixins/search'
export default {
  name: 'searchMessage',
  components: { SingleMessage, SearchLeft },
  mixins: [cacheMixin, requestMixin],
  data() {
    const pickerOptionsStart = (rule, value, callback) => {
      const endDateVal = this.queryForm.send_time_before
      if (endDateVal) {
        if (new Date(value) > new Date(endDateVal)) {
          return callback(new Error('开始时间要在结束时间之前'))
        } else {
          callback()
        }
      }
    }
    const pickerOptionsEnd = (rule, value, callback) => {
      const startDateVal = this.queryForm.send_time_after
      if (startDateVal) {
        if (new Date(value) < new Date(startDateVal)) {
          return callback(new Error('结束时间要在开始时间之后'))
        } else {
          callback()
        }
      }
    }
    return {
      list: [],
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      multipleSelection: [],
      queryForm: {
        page_index: 1,
        page_size: 10,
        ordering: '-send_time'
      },
      queryFormRules: {
        send_time_after: [
          {
            validator: pickerOptionsStart,
            trigger: 'change'
          }
        ],
        send_time_before: [
          {
            validator: pickerOptionsEnd,
            trigger: 'change'
          }
        ]
      },
      listScrollTop: 0
    }
  },
  created() {
    this.$nextTick(() => {
      this.requestFun()
    })
  },
  activated() {
    scrollTopFun('search-msg-list', 'set', this.listScrollTop)
  },
  deactivated() {
    this.listScrollTop = scrollTopFun('search-msg-list', 'get')
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    async fetchData() {
      this.listLoading = true
      let obj = {}
      for (let i in this.queryForm) {
        if (this.queryForm[i]) {
          obj[i] = this.queryForm[i]
        }
      }
      const res = await messagesApi(obj)
      this.count = res.count
      this.list = res.items
      this.listLoading = false
    },

    handleSizeChange(pageSize) {
      //
      this.listScrollTop = 0
      scrollTopFun('search-msg-list', 'set', this.listScrollTop)
      this.queryForm.page_size = pageSize

      let page_size = parseInt(this.queryForm.page_size)
      let page_index = parseInt(this.queryForm.page_index)
      if (typeof page_size === 'number' && typeof page_index === 'number') {
        if (page_size * page_index > 10000) {
          this.$message.warning(
            '最多返回10000条聊天记录，请细化检索条件获取所需数据。'
          )
          return
        }
      } else {
        this.$message.warning('分页错误！')
        return
      }
      // 分页变更时处理
      this.requestFun()
    },
    handleCurrentChange(pageIndex) {
      //
      this.listScrollTop = 0
      scrollTopFun('search-msg-list', 'set', this.listScrollTop)
      this.queryForm.page_index = pageIndex

      //
      let page_size = parseInt(this.queryForm.page_size)
      let page_index = parseInt(this.queryForm.page_index)
      if (typeof page_size === 'number' && typeof page_index === 'number') {
        if (page_size * page_index > 10000) {
          this.$message.warning(
            '最多返回10000条聊天记录，请细化检索条件获取所需数据。'
          )
          return
        }
      } else {
        this.$message.warning('分页错误！')
        return
      }
      // 分页变更时处理
      this.requestFun()
    },
    allCountFun() {
      let page_size = parseInt(this.queryForm.page_size)
      let page_index = parseInt(this.queryForm.page_index)
      if (typeof page_size === 'number' && typeof page_index === 'number') {
        if (page_size * page_index > 10000) {
          this.$message.warning(
            '最多返回10000条聊天记录，请细化检索条件获取所需数据。'
          )
          return
        }
      } else {
        this.$message.warning('分页错误！')
        return
      }
    },
    // 右侧检索按钮
    submitFun() {
      this.queryForm.page_index = 1
      this.list = []
      let leftObj = this.$refs.searchLeftRef.queryForm
      let rightObj = this.$refs.searchRightRef.queryForm

      let query = {
        ...leftObj,
        ...rightObj
      }
      this.queryForm = { ...this.queryForm, ...query }
      if (this.queryForm.radio == 1) {
        this.getMsg1()
      } else if (this.queryForm.radio == 2) {
        this.getMsg2()
      } else if (this.queryForm.radio == 3) {
        this.getMsg3()
      }
    },
    requestFun() {
      let leftObj = this.$refs.searchLeftRef.queryForm
      let rightObj = this.$refs.searchRightRef.queryForm

      let query = {
        ...leftObj,
        ...rightObj
      }
      this.queryForm = { ...this.queryForm, ...query }
      if (this.queryForm.radio == 1) {
        this.getMsg1()
      } else if (this.queryForm.radio == 2) {
        this.getMsg2()
      } else if (this.queryForm.radio == 3) {
        this.getMsg3()
      }
    },
    updateFun() {
      this.requestFun()
    }
  }
}
</script>
<style lang="scss" scoped>
.msg-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 70px);
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}
.msg-left {
  width: 300px;
}
.msg-right {
  width: 300px;
}

.bottom_box {
  width: calc(100% - 620px);
  height: 100%;

  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  .content_t {
    height: calc(100% - 35px);
    overflow: auto;
  }
  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #8b8d8d;
  }
}
</style>
